import { mapState } from 'vuex'

export default mapState({
    cart: function (state) {
        return state.cart
    },
    totalValue: function(state) {
        let value = (
            state.cart.optionals.map(optional => optional.price * optional.quantity).reduce((partial, a) => partial + a, 0) +
            state.cart.accommodations.map(accommodation => accommodation.price * accommodation.quantity).reduce((partial, a) => partial + a, 0)
        )

        if (state.cart.coupon) {
            if (state.cart.coupon.type === 'percent') {
                value = value - ((value * state.cart.coupon.amount) / 100)
            }

            if (state.cart.coupon.type === 'fixed_amount') {
                value -= state.cart.coupon.amount
            }

            if (value < 0) {
                value = 0
            }
        }

        return value
    },
    canCheckout: function() {
        const allCpfs = this.travelers.filter(traveler => !traveler.noCpf).map(traveler => traveler.document)
        const allRgs = this.travelers.filter(traveler => !traveler.noRg).map(traveler => traveler.rg)

        return (
            ((this.paymentMethod === 'credit_card') ||
            (this.paymentMethod === 'pix')) &&
            (this.allTravelersFilled && this.acceptedTerms.length === this.totalTerms) &&
            (!this.findDuplicates(allCpfs) && !this.findDuplicates(allRgs)) &&
            (!this.hasInvalidCpf(allCpfs) && !this.hasInvalidRg(allRgs))
        )
    },
    totalPeople(state) {
        return (
            state.cart.accommodations.map(accommodation => accommodation.quantity).reduce((partial, a) => partial + a, 0)
        )
    },
    orderData: function(state) {
        let accommodations = state.cart.accommodations
        accommodations.forEach(accommodation => {
            accommodation.optionTypeId = accommodation.id
            accommodation.title = accommodation.name
        })

        state.paymentMethod = this.paymentMethod

        return {
            accommodations,
            acceptedAgencyTerms: this.terms?.agencyTerms?.id ? `/terms/${this.terms.agencyTerms.id}` : null,
            acceptedCompanyTerms: this.terms?.companyTerms?.id ? `/terms/${this.terms.companyTerms.id}` : null,
            cart: state.cart.id,
            optionals: state.cart.optionals,
            travelers: state.cart.travelers,
            paymentData: {
                installments: {
                    code: state.installments.value,
                    total: state.installments.total
                },
                type: this.paymentMethod
            }
        }
    },
    errors() {
        const result = []

        const allCpfs = this.travelers.filter(traveler => !traveler.noCpf).map(traveler => traveler.document)
        const allRgs = this.travelers.filter(traveler => !traveler.noRg).map(traveler => traveler.rg)

        if (!this.allTravelersFilled) {
            result.push({
                hash: '#travelers-header',
                path: 'travelers',
                message: 'Preencha os dados dos mochileiros'
            })
        }

        if (
          this.findDuplicates(allCpfs) || this.findDuplicates(allRgs) || this.hasInvalidCpf(allCpfs) || this.hasInvalidRg(allRgs)
        ) {
            result.push({
                hash: '#travelers-header',
                path: 'travelers',
                message: 'Preencha todos os campos e tente novamente'
            })
        }

        if (this.acceptedTerms.length !== this.totalTerms) {
            result.push({
                hash: '#terms-header',
                path: 'terms',
                message: 'Leia e aceite os termos de uso'
            })
        }

        return result
    },
    validationErrors() {
        return this.$store.state.errors
    }
})
